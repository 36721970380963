module.exports = [{
      plugin: require('/Users/logickal/Dev/offnominal-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/Users/logickal/Dev/offnominal-gatsby/node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/logickal/Dev/offnominal-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/logickal/Dev/offnominal-gatsby/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/logickal/Dev/offnominal-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
