// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-blog-jsx": () => import("/Users/logickal/Dev/offnominal-gatsby/src/layouts/Blog.jsx" /* webpackChunkName: "component---src-layouts-blog-jsx" */),
  "component---src-layouts-page-jsx": () => import("/Users/logickal/Dev/offnominal-gatsby/src/layouts/Page.jsx" /* webpackChunkName: "component---src-layouts-page-jsx" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/logickal/Dev/offnominal-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-category-jsx": () => import("/Users/logickal/Dev/offnominal-gatsby/src/layouts/Category.jsx" /* webpackChunkName: "component---src-layouts-category-jsx" */),
  "component---src-pages-404-js": () => import("/Users/logickal/Dev/offnominal-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/Users/logickal/Dev/offnominal-gatsby/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-elements-js": () => import("/Users/logickal/Dev/offnominal-gatsby/src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("/Users/logickal/Dev/offnominal-gatsby/src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("/Users/logickal/Dev/offnominal-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

